import styled from '@emotion/styled';

import {
  laptopQuery,
  desktopQuery,
  mobileToTabletQuery,
  laptopToDesktopQuery,
} from '../utils/mediaqueries';

const ModulePageContentWrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  overflowX: 'hidden',
  overFlowY: 'auto',
  marginBottom: '30px',
  position: 'absolute',

  [mobileToTabletQuery]: {
    margin: '0 30px',
  },

  [laptopQuery]: {
    width: '62.5%',
    right: 0,
  },

  [desktopQuery]: {
    width: '63.8%',
  },

  [laptopToDesktopQuery]: {
    right: 0,
    marginLeft: '30px',
  },
});

export default ModulePageContentWrapper;
