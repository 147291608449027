import React from 'react';

import theme from '../utils/theme';
import styled from '@emotion/styled';
import {
  mobileQuery,
  laptopQuery,
  desktopQuery,
  tabletQuery,
} from '../utils/mediaqueries';

const ModuleInfoWrapper = styled.div({
  display: 'flex',
});

const HeadingText = styled.p({
  color: theme.colors.descriptionText,
  margin: 0,
  fontSize: '0.875rem',
  marginRight: '8px',

  [mobileQuery]: {
    width: '30%',
  },

  [tabletQuery]: {
    width: '30%',
  },

  [laptopQuery]: {
    width: '14%',
  },

  [desktopQuery]: {
    width: '9.5%',
  },
});

const DescriptionText = styled.p({
  color: theme.colors.mainText,
  margin: 0,
  fontSize: '0.875rem',
});

const ModuleInfo = ({ heading, content }) => (
  <ModuleInfoWrapper>
    <HeadingText>{heading}</HeadingText>
    <DescriptionText>{content}</DescriptionText>
  </ModuleInfoWrapper>
);

export default ModuleInfo;
