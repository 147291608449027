import React from "react";

import ModuleInfoWrapper from "./ModuleInfoWrapper";
import ModuleInfo from "./ModuleInfo";

const ModuleInfoGroup = ({ faculty, programme, language, form, type }) => {
  const renderForm = () => {
    if (form.length > 1) {
      form.splice(1, 0, " | ");
    }

    return form.map((f) => f);
  };

  return (
    <ModuleInfoWrapper>
      <ModuleInfo heading='FAKULTA' content={faculty} />
      {programme && <ModuleInfo heading='PROGRAM' content={programme} />}
      <ModuleInfo heading='TYP' content={type} />
      <ModuleInfo heading='JAZYK' content={language} />
      <ModuleInfo heading='FORMA' content={renderForm()} />
    </ModuleInfoWrapper>
  );
};

export default ModuleInfoGroup;
