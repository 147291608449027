import React from 'react';

import styled from '@emotion/styled';
import theme from '../utils/theme';

import { mobileQuery } from '../utils/mediaqueries';
import { facultyDescriptions } from '../seo/MainPageSeo';
import { createLink } from '../utils/functions';

import ExcerptWrapper from '../components/ExcerptWrapper';
import Header from '../components/Header';
import Layout from '../components/layout/Layout';
import ModuleInfoGroup from '../components/ModuleInfoGroup';
import ModulePageContentWrapper from '../components/ModulePageContentWrapper';
import NavExcerpt from '../components/NavExcerpt';
import NavMenu from '../components/NavMenu';
import ReactMarkdown from 'react-markdown';
import Seo from '../components/Seo';
import Button from '../components/button/Button';

const Heading = styled.h1({
  color: theme.colors.mainText,
  margin: 0,

  [mobileQuery]: {
    marginTop: '60px',
  },
});

const ModuleDescription = styled.div({
  color: theme.colors.mainText,
  margin: '30px 0',
  whiteSpace: 'pre-wrap',

  '&>h2, &>ul, &>p, &>li': {
    margin: 0,
  },

  '&>p': {
    lineHeight: '20px',
  },

  '&>ul>li': {
    lineHeight: '15px',
  },
});

const ModuleDetailTemplate = ({ uri, pageContext }) => {
  const { studyExcerpts: navExcerpts, description, faculty, level, quickInfo, title } = pageContext;

  const { form, language, programme } = quickInfo;

  const metaDescription = facultyDescriptions[createLink(faculty)];
  const metaImage = facultyDescriptions[createLink(faculty)];

  const renderExcerpts = () =>
    navExcerpts.map(({ heading, description, thumbnail }) => (
      <NavExcerpt
        currentUrl={uri}
        key={heading}
        heading={heading}
        description={description}
        thumbnail={thumbnail}
        to={`/studium/${createLink(heading)}`}
      />
    ));

  return (
    <>
      <Seo title={`${title} | ${faculty}`} description={metaDescription} image={metaImage} />
      <Layout>
        <NavMenu hide />
        <ExcerptWrapper hide>{renderExcerpts()}</ExcerptWrapper>
        <ModulePageContentWrapper>
          <Header sticky displayDesktop backButton />
          <Heading>{title}</Heading>
          <ModuleInfoGroup faculty={faculty} form={form} language={language} programme={programme} type={level} />
          <ModuleDescription>
            <ReactMarkdown children={description} />
          </ModuleDescription>
          <Button href="https://prihlaska.utb.cz/" target="_blank">
            E-přihláška
          </Button>
        </ModulePageContentWrapper>
      </Layout>
    </>
  );
};

export default ModuleDetailTemplate;
