import styled from "@emotion/styled";
import theme from "../../utils/theme";

const Button = styled.a({
  backgroundColor: theme.colors.orangeBackground,
  border: "none",
  color: "white",
  cursor: "pointer",
  display: "block",
  fontSize: "16px",
  padding: "15px 32px",
  textAlign: "center",
  textDecoration: "none",
  width: "120px",
  alignSelf: "center",
  marginBottom: "40px",
});

export default Button;
